import { authService } from '../../helpers/services/auth.service';
import router from '../../router/index'
import { sessionHelper, sharedHelper } from '@/utils'
import { SESSION } from '@/constants'
import i18n from '@/i18n'

const user = JSON.parse(localStorage.getItem('user'));
export const state = user
    ? { status: { loggeduser: true }, user }
    : { status: {}, user: null };

export const MODULE = 'auth'; 

// define action 
export const LOGIN = 'login'; 
export const RESET_PASSWORD = 'resetPassword'; 
export const UPDATE_PASSWORD = 'updatePassword'; 
export const LOGOUT = 'logout';
export const REFRESH_TOKEN = 'refreshToken'; 

// define mutation
const REQUESTING = "requesting";
const LOGIN_SUCCESS = "loginSuccess";
const LOGOUT_SUCCESS = "logout";
const SUCCESS = "commonSuccess";
const FAILURE = "commonFailure";
const REFRESH_TOKEN_SUCCESS = "refreshTokenSuccess";

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.user
    },
}

export const actions = {

    async [LOGIN] ({ commit }, { data }) {
        commit(REQUESTING);
        authService.login(data).then(
            user => {
                commit(LOGIN_SUCCESS, user);  
            },
            error => {
                let message = sharedHelper.getErrorMessage(error);
                sharedHelper.alertErrorMessage(message, error.response.data.code)
                commit(FAILURE, error);
            }
        )
    },
    async [LOGOUT] ({ commit }) {
        commit(LOGOUT_SUCCESS);
        let message = i18n.t("message.logoutSuccess");
        sharedHelper.alertSuccessMessage(message);
        commit(SUCCESS);
    },

    async [REFRESH_TOKEN] ({ commit }) {
        commit(REQUESTING);
        await authService.refreshToken().then(
            
            // eslint-disable-next-line no-unused-vars
            user => {
                commit(REFRESH_TOKEN_SUCCESS, user);
            }, 
            error => {
                let message = sharedHelper.getErrorMessage(error);
                sharedHelper.alertErrorMessage(message, error.response.data.code)
                commit(FAILURE, error);
            }
        );
    },
    [RESET_PASSWORD] ({ commit }, { data }) {
        commit(REQUESTING);
        authService.resetPassword(data).then(
            // eslint-disable-next-line no-unused-vars
            result => {
                let message = i18n.t("message.checkYourEmailForResetPassword");
                sharedHelper.alertSuccessMessage(message)
                commit(SUCCESS);
            },
            error => {
                let message = sharedHelper.getErrorMessage(error);
                sharedHelper.alertErrorMessage(message, error.response.data.code)
                commit(FAILURE, error);
            }
        )
    },
    [UPDATE_PASSWORD] ({ commit }, { data }) {
        commit(REQUESTING);
        authService.updatePassword(data).then(
            // eslint-disable-next-line no-unused-vars
            result => {
                let message = i18n.t("message.updateXSuccess", [i18n.t('label.password')]);
                sharedHelper.alertSuccessMessage(message)
                commit(SUCCESS);
            },
            error => {
                let message = sharedHelper.getErrorMessage(error);
                sharedHelper.alertErrorMessage(message, error.response.data.code)
                commit(FAILURE, error);
            }
        )
    },
};

export const mutations = {
    [REQUESTING](state) {
        state.status = { processiong: true };
    },
    [SUCCESS](state) {
        state.status = {};
        router.push('/login');      
    },
    [FAILURE](state) {
        state.status = {};
    },
    [LOGIN_SUCCESS](state, user) {
        state.status = { loggeduser: true };
        state.user = user;

        var tokenExpiredTime = sessionHelper.calculateTime(user.data.token.expires_in) 
        var refreshTokenExpiredTime = sessionHelper.calculateTime(user.data.token.refresh_expires_in) 

        localStorage.setItem(SESSION.USER_ID, user.data.user.uuid)
        localStorage.setItem(SESSION.USER_EMAIL, user.data.user.email)
        localStorage.setItem(SESSION.USER_NAME, user.data.user.name)
        localStorage.setItem(SESSION.JWT_TOKEN, user.data.token.access_token)
        localStorage.setItem(SESSION.TOKEN_EXPIRY_TIME, tokenExpiredTime)
        localStorage.setItem(SESSION.REFRESH_TOKEN_EXPIRY_TIME, refreshTokenExpiredTime)
        localStorage.setItem(SESSION.PERMISSIONS, JSON.stringify(user.data.user.roles[0].permissions))
        localStorage.setItem(SESSION.USER, JSON.stringify(user));
    
        router.push('/');      
    },
    [LOGOUT_SUCCESS](state) {
        sessionHelper.clearAllClientStorage()
        state.status = {};
        state.user = null;
    },
    [REFRESH_TOKEN_SUCCESS](state, user) {
        state.status = {};

        var tokenExpiredTime = sessionHelper.calculateTime(user.data.token.expires_in) 
        var refreshTokenExpiredTime = sessionHelper.calculateTime(user.data.token.refresh_expires_in) 

        localStorage.setItem(SESSION.USER_ID, user.data.user.uuid)
        localStorage.setItem(SESSION.USER_EMAIL, user.data.user.email)
        localStorage.setItem(SESSION.USER_NAME, user.data.user.name)
        localStorage.setItem(SESSION.JWT_TOKEN, user.data.token.access_token)
        localStorage.setItem(SESSION.TOKEN_EXPIRY_TIME, tokenExpiredTime)
        localStorage.setItem(SESSION.REFRESH_TOKEN_EXPIRY_TIME, refreshTokenExpiredTime)
        localStorage.setItem(SESSION.PERMISSIONS, JSON.stringify(user.data.user.roles[0].permissions))
        localStorage.setItem(SESSION.USER, JSON.stringify(user));

    },
};

