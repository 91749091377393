import { apiHelper } from '@/utils'
import { API } from '@/constants'
import { sharedHelper } from '@/utils';

export const authService = {
    login,
    resetPassword,
    updatePassword,
    revokeAuthToken, 
    refreshToken 
};

const MODULE = {
    AUTH: 'auth',
}

const API_URL = API.BASE_URL + '/v1'

function login(data) {
    const requestOptions = {
        email: data.email,
        password: data.password,
        domain: data.domain,
        platform: data.platform,
        option: data.option,
        login_from:"MANDRILL"
    }
    
    return apiHelper(API_URL, false, false).post(`/${MODULE.AUTH}/external-token`, requestOptions)
}

function resetPassword(data) {
    const requestOptions = {
        params: {
            email: data.email,
            medium: data.medium
        }
    }

    return apiHelper(API_URL, true, false).get(`/${MODULE.AUTH}/password`, requestOptions)
}

function updatePassword(data) {
    const requestOptions = {
        token: data.token,
        password: data.password,
        password_confirmation: data.confirmationPassword,
        purpose: data.purpose
    }

    return apiHelper(API_URL, true, false).patch(`/${MODULE.AUTH}/password`, requestOptions)
}

function revokeAuthToken() {
    const requestOptions = {
        platform: sharedHelper.getPlatform(),
    }

    return apiHelper(API_URL, true, false).put(`/${MODULE.AUTH}/token-revocation`, requestOptions)
}

function refreshToken() {
    const requestOptions = {
        platform: sharedHelper.getPlatform(),
    }

    return apiHelper(API_URL, true, false).put(`/${MODULE.AUTH}/external-token`, requestOptions)
}