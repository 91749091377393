/* Functions here is to handle everything about
 * localStorage, sessionStorage or cookie
 */
import { SESSION, SHARED } from '@/constants'

export function getUserEmail() {
  let email = localStorage.getItem(SESSION.USER_EMAIL)

  if (email) {
    return email;
  } else {
    return '';
  }

}

export function getUserId() {
  return localStorage.getItem(SESSION.USER_ID)
}

export function getUserName() {
  return localStorage.getItem(SESSION.USER_NAME)
}

export function getUserToken() {
  return localStorage.getItem(SESSION.JWT_TOKEN)
}

export function getUserPermissions() {
  return localStorage.getItem(SESSION.PERMISSIONS)
}

export function getUserExpiredTime() {
  return localStorage.getItem(SESSION.TOKEN_EXPIRY_TIME)
}

export function getUserRefreshExpiredTime() {
  return localStorage.getItem(SESSION.REFRESH_TOKEN_EXPIRY_TIME)
}

export function clearAllClientStorage() {
  localStorage.clear()
  sessionStorage.clear()
}

export function isTokenExpired() {
  let result = true
  let tokenExpiry = localStorage.getItem(SESSION.TOKEN_EXPIRY_TIME)

  if (tokenExpiry && !isNaN(Date.parse(tokenExpiry))) {
    let currentTime = new Date(); // Current time
  let tokenExpiryDate = new Date(tokenExpiry); // Token expiry time

   result = currentTime <= tokenExpiryDate;
  }

  return result
}

export function isRefreshTokenExpired() {
  let result = true
  let tokenExpiry = localStorage.getItem(SESSION.REFRESH_TOKEN_EXPIRY_TIME)

  if (tokenExpiry && !isNaN(Date.parse(tokenExpiry))) {
    let currentTime = new Date(); // Current time
  let tokenExpiryDate = new Date(tokenExpiry); // Token expiry time

   result = currentTime <= tokenExpiryDate;
  }

  return result
}

export function calculateTime(expiredMinute) {
  var currentDate = new Date(new Date().toLocaleString("en-US", {timeZone: "Asia/kuala_lumpur"}));
  expiredMinute = parseInt(expiredMinute);
  currentDate.setMinutes(currentDate.getMinutes() + expiredMinute);
  
  return currentDate;
}

export function hasAuthToken() {
  let result = false

  if (getUserToken()) {
    result = true
  }

  return result
}

export function isActiveUser() {
  let result = false
  let lastActivityTime = localStorage.getItem(SESSION.LAST_ACTIVITY_TIME)

  if (lastActivityTime) {
    result = new Date().getTime() - parseInt(lastActivityTime) < SHARED.USER_IDLE_TIME
  }

  return result
}

/* START Search Criteria */
export function storeSearch(name, value) {
  let str = typeof value === 'string' ? value : JSON.stringify(value)
  sessionStorage.setItem(name, str)
}

export function retrieveSearch(name) {
  let f = sessionStorage.getItem(name)
  let result = f != undefined && f != null && f != '' ? JSON.parse(f) : ''

  return result
}

export function clearSearch(name) {
  sessionStorage.removeItem(name)
}

export function hasSearchCriteria(name) {
  let f = sessionStorage.getItem(name)
  return f != undefined && f != null && f != ''
}

/* END Search Criteria */

