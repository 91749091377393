export const SHARED = Object.freeze({
    DEFAULT_LAYOUT: 'app-layout',
    PUBLIC_LAYOUT: 'app-layout-public',
    EMPTY_LAYOUT: 'app-layout-empty',
    DEFAULT_LANGUAGE: 'en',
    FALLBACK_LANGUAGE: 'en',
    DEFAULT_ALL_VALUE: -1,
    DEFAULT_ALL_VALUE_STR: '',
    DEFAULT_PAGE_NUMBER: 1,
    DEFAULT_PAGE_SIZE: 10,
    USER_IDLE_TIME: process.env.VUE_APP_USER_IDLE_TIME //3600000=60 Minutes
  })
  
  export const SESSION = Object.freeze({
    USER_ID: 'uuid',
    USER: 'user',
    USER_EMAIL: 'email',
    USER_NAME: 'name',
    JWT_TOKEN: 'token',
    TOKEN_EXPIRY_TIME: 'authTokenExpiryTime',
    REFRESH_TOKEN_EXPIRY_TIME: 'authRefreshTokenExpiryTime',
    PERMISSIONS: 'permissions',
    USER_SEARCH: 'userSearch',
    ROLE_SEARCH: 'roleSearch',
    STAFF_SEARCH: 'staffSearch',
    STAFF_TYPE_SEARCH: 'staffTypeSearch',
    MEETING_ROOM_SEARCH: 'meetingRoomSearch',

  })

  export const APIHELPER = Object.freeze({
    NETWORK_ERROR: "Network Error", 
    UNKNOWN_ERROR: "Unknown Error", 
    INVALID_USER: "Invalid User", 
    OBJECT: "object", 
    OBJECT_INVALID: " is Invalid", 
    NETWORK_ERROR_CODE: "0.96", 
    UNKNOWN_ERROR_CODE: "0.99", 
  })
  
  export const API = Object.freeze({
    BASE_URL: process.env.VUE_APP_API_BASE_URL,
  })
  