import { meetingRoomService } from '../../helpers/services/meetingRoom.service';
import { sharedHelper } from '@/utils'

export const state = {
    list: {
        data: [],
        pagination: {
            pageNumber: 0,
            pageSize: 0,
            lastPage: 0,
            tableRowsCount: 0
        }
    },
    details: {},
    status: {}
}

export const ROOM_MODULE = "meetingRoom";

// define action 
export const GET_ALL_ROOMS = 'getAllRooms';

// define mutation
const REQUESTING = "requesting";
const ALL_ROOM_SUCCESS = "allRoomSuccess";
const FAILURE = "commonFailure";

export const actions = {

    // eslint-disable-next-line no-unused-vars
    [GET_ALL_ROOMS]({ commit }, { filter }) {
        meetingRoomService
            .getAllRooms(filter)
            .then(
                (result) => {
                    commit(ALL_ROOM_SUCCESS, { data: result })
                },
                (error) => {
                    let message = sharedHelper.getErrorMessage(error);
                    sharedHelper.alertErrorMessage(message, error.response.data.code)
                    commit(FAILURE);
                }
            )
    },

};

export const mutations = {
    [ALL_ROOM_SUCCESS](state, { data }) {
        let d = data.data

        state.list.data = []
        state.list.pagination = sharedHelper.apiPaginationAdapter(data.pagination)

        if (data.success) {
            let list = []

            d.forEach((x) => {
                list.push({
                    id: x.id,
                    name: x.name,
                    size: x.size,
                    image: x.image[0].url,
                })
            })
            state.list.data = list
        }
    },
    [FAILURE](state) {
        state.status = {};
    },
    [REQUESTING](state) {
        state.status = { processiong: true };
    },
}


