import store from '@/state/store'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                if (store.getters['auth/loggedIn']) {
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                if (store.getters['auth/loggedIn']) {
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            async beforeResolve(routeTo, routeFrom, next) {
                await store.dispatch('auth/logout')

                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
            },
        },
    },
    {
        path: '/reset-password',
        name: 'Reset password',
        component: () => import('../views/pages/account/reset-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                next()
            },
        },
    },
    {
        path: '/first-time-password',
        name: 'Create-password',
        component: () => import('../views/pages/account/create-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                next()
            },
        },
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/meeting-room/roomListing')
    },
    {
        path: '/profile',
        name: 'Profile',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/user/profile')
    },
    {
        path: '/user',
        name: 'User-listing',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/user/userListing')
    },
    {
        path: '/user/update',
        name: 'User-update',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/user/userUpdate')
    },
    {
        path: '/user/create',
        name: 'User-create',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/user/userCreate')
    },
    {
        path: '/meeting-room',
        name: 'Meeting-room-listing',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/meeting-room/roomListing')
    },
    {
        path: '/calendar',
        name: 'Calendar',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/calendar/index')
    },
    {
        path: '/all-booking',
        name: 'All-booking',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/calendar/all-index')
    },
]
