import { apiHelper } from '@/utils'
import { API } from '@/constants'
// const moment = require('moment'); 

export const meetingRoomBookingService = {
    getAllBookings, 
    createBooking,
    updateBooking,
    getBooking, 
    deleteBooking
  };

const MODULE = {
    BOOKING: 'meeting-room-bookings',
}

// const API_URL = API.BASE_URL.replace('@serviceName', 'user') + '/v1'
const API_URL = API.BASE_URL + '/v1'


export function getAllBookings(filter) {
    const requestOptions = {
      params: {
        meeting_room_id: filter.meetingRoomId,
        meeting_date_start: filter.dateStart,
        meeting_date_end: filter.dateEnd,
        company_id: filter.companyId, 
      }
    }

    let array = filter.searchArray
    array.forEach((word, index) => {
      if(word != null || word != '')
      {
        var searchParam = `search_words[${index}]`
        requestOptions.params[searchParam] = word
      }
    })

    return apiHelper(API_URL).get(`/${MODULE.BOOKING}`, requestOptions)
}

export function createBooking(data) {

  const requestOptions = {
    detail: data['detail'],
    agenda: data['title'],
    date: data['date'],
    end: data['end'].slice(0, 5),
    start: data['start'].slice(0, 5),
    meeting_room_id: data['meetingRoomId'],
  }

  return apiHelper(API_URL).post(`/${MODULE.BOOKING}`, requestOptions)
}

export function getBooking(id) {
  return apiHelper(API_URL).get(`/${MODULE.BOOKING}/${id}`)
}

export function updateBooking(data) {
  const requestOptions = {
    detail: data.detail,
    agenda: data.title,
    date: data['date'],
    end: data['end'].slice(0, 5),
    start: data['start'].slice(0, 5),
    meeting_room_id: data['meetingRoomId'],
  }


  return apiHelper(API_URL).put(`/${MODULE.BOOKING}/${data.id}`, requestOptions)
}

export function deleteBooking(id) {
  return apiHelper(API_URL).delete(`/${MODULE.BOOKING}/${id}`)
}