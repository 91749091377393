import { apiHelper } from '@/utils'
import { API } from '@/constants'

export const meetingRoomService = {
    getAllRooms, 
};

const MODULE = {
    ROOM: 'meeting-rooms',
}

const API_URL = API.BASE_URL + '/v1'


export function getAllRooms(filter) {

    const requestOptions = {
      params: {
        page: filter.pageNumber,
        size: filter.pageSize,
        company_id: '85',
        is_active: filter.isActive
      }
    }

    let array = filter.searchArray
    array.forEach((word, index) => {
      if(word != null || word != '')
      {
        var searchParam = `search_words[${index}]`
        requestOptions.params[searchParam] = word
      }
    })
    
    return apiHelper(API_URL).get(`/${MODULE.ROOM}`, requestOptions)
}