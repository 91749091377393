import { userService } from '../../helpers/services/user.service';
import i18n from '@/i18n'
import router from '@/router/index'
import { sessionHelper, sharedHelper } from '@/utils'

export const state = {
  list: {
    data: [],
    pagination: {
      pageNumber: 0,
      pageSize: 0,
      lastPage: 0,
      tableRowsCount: 0
    }
  },
  details: {},
  status: {}
}

export const USER_MODULE = "user";

// define action 
export const GET_ALL_USERS = 'getAllUser';
export const UPDATE_USER_STATUS = 'updateUserStatus';
export const GET_USER = 'getUser';
export const GET_PROFILE = 'getProfile';
export const UPDATE_USER = 'updateUser';
export const UPDATE_PROFILE = 'updateProfile';
export const UNLOCK_USER = 'unlockUser';
export const CREATE_USER = 'createUser';

// define mutation
const REQUESTING = "requesting";
const ALL_USER_SUCCESS = "allUserSuccess";
const SUCCESS = "commonSuccess";
const FAILURE = "commonFailure";
const GET_USER_SUCCESS = "getUserSuccess";
const UPDATE_USER_SUCCESS = "updateUserSuccess";

export const actions = {

  // eslint-disable-next-line no-unused-vars
  [GET_ALL_USERS]({ commit }, { filter }) {
    userService
      .getAllUsers(filter)
      .then(
        (result) => {
          commit(ALL_USER_SUCCESS, { data: result })
        },
        (error) => {
          let message = sharedHelper.getErrorMessage(error);
          sharedHelper.alertErrorMessage(message, error.response.data.code)
          commit(FAILURE);
        }
      )
  },

  // eslint-disable-next-line no-unused-vars
  [UPDATE_USER_STATUS]({ dispatch, commit }, { filter, data }) {
    userService.updateUserStatus(data)
      .then(
        // eslint-disable-next-line no-unused-vars
        (result) => {
          var message = i18n.t('message.updateStatusSuccess');
          sharedHelper.alertSuccessMessage(message)
          dispatch(GET_ALL_USERS, { filter })
        },
        (error) => {
          let message = sharedHelper.getErrorMessage(error);
          sharedHelper.alertErrorMessage(message, error.response.data.code)
          commit(FAILURE);
        }
      )
  },

  // eslint-disable-next-line no-unused-vars
  [GET_USER]({ commit }, { id }) {
    userService
      .getUser(id)
      .then(
        (result) => {
          commit(GET_USER_SUCCESS, result)
        },
        (error) => {
          let message = sharedHelper.getErrorMessage(error);
          sharedHelper.alertErrorMessage(message, error.response.data.code)
          commit(FAILURE);
        }
      );
  },

  // eslint-disable-next-line no-unused-vars
  [GET_PROFILE]({ commit }) {
    var id = sessionHelper.getUserId();
    userService
      .getUser(id)
      .then(
        (result) => {
          commit(GET_USER_SUCCESS, result)
        },
        (error) => {
          let message = sharedHelper.getErrorMessage(error);
          sharedHelper.alertErrorMessage(message, error.response.data.code)
          commit(FAILURE);
        }
      );
  },


  // eslint-disable-next-line no-unused-vars
  [UPDATE_USER]({ commit }, { data }) {
    commit(REQUESTING);
    userService
      .updateUser(data)
      .then(
        // eslint-disable-next-line no-unused-vars
        (result) => {
          var message = i18n.t("message.updateXSuccess", [i18n.t('module.user')]);
          sharedHelper.alertSuccessMessage(message)
          commit(UPDATE_USER_SUCCESS);
        },
        (error) => {
          let message = sharedHelper.getErrorMessage(error);
          sharedHelper.alertErrorMessage(message, error.response.data.code)
          commit(FAILURE);
        }
      );
  },

  // eslint-disable-next-line no-unused-vars
  [UPDATE_PROFILE]({ commit, dispatch }, { data }) {
    commit(REQUESTING);
    userService
      .updateProfile(data)
      .then(
        // eslint-disable-next-line no-unused-vars
        (result) => {
          commit(SUCCESS);
          var message = i18n.t("message.updateXSuccess", [i18n.t('module.profile')]);
          sharedHelper.alertSuccessMessage(message)
          dispatch(GET_PROFILE)
        },
        (error) => {
          let message = sharedHelper.getErrorMessage(error);
          sharedHelper.alertErrorMessage(message, error.response.data.code)
          commit(FAILURE);
        }
      );
  },

  // eslint-disable-next-line no-unused-vars
  [UNLOCK_USER]({ dispatch, commit }, { id }) {
    commit(REQUESTING);
    userService.unlockUser(id)
      .then(
        // eslint-disable-next-line no-unused-vars
        (result) => {
          commit(SUCCESS);
          var message = i18n.t('message.updateStatusSuccess');
          sharedHelper.alertSuccessMessage(message)
          dispatch(GET_USER, { id })
        },
        (error) => {
          let message = sharedHelper.getErrorMessage(error);
          sharedHelper.alertErrorMessage(message, error.response.data.code)
          commit(FAILURE);
        }
      )
  },

  // eslint-disable-next-line no-unused-vars
  [CREATE_USER]({ commit }, { data }) {
    commit(REQUESTING);
    userService
      .createUser(data)
      .then(
        // eslint-disable-next-line no-unused-vars
        (result) => {
          var message = i18n.t("message.createXSuccess", [i18n.t('module.user')]);
          sharedHelper.alertSuccessMessage(message)
          commit(UPDATE_USER_SUCCESS);
        },
        (error) => {
          let message = sharedHelper.getErrorMessage(error);
          sharedHelper.alertErrorMessage(message, error.response.data.code)
          commit(FAILURE);
        }
      )
  },

};

export const mutations = {
  [ALL_USER_SUCCESS](state, { data }) {
    let d = data.data

    state.list.data = []
    state.list.pagination = sharedHelper.apiPaginationAdapter(data.pagination)

    if (data.success) {
      let list = []

      d.forEach((x) => {
        list.push({
          id: x.uuid,
          name: x.name,
          email: x.email,
          isVerified: x.is_verified,
          isLocked: x.is_locked,
          isActive: x.is_active,
          createdBy: x.created_by,
          createdDateTime: x.created_date_time,
          updatedBy: x.updated_by,
          updatedDateTime: x.updated_date_time
        })
      })

      state.list.data = list
    }
  },
  [SUCCESS](state) {
    state.status = {};
  },
  [FAILURE](state) {
    state.status = {};
  },
  [REQUESTING](state) {
    state.status = { processiong: true };
},
  [UPDATE_USER_SUCCESS](state) {
    state.status = {};
    router.push({ name: "User-listing" })
  },
  [GET_USER_SUCCESS](state, data) {
    let x = data.data
    state.details = {
      id: x.uuid,
      name: x.name,
      email: x.email,
      isVerified: x.is_verified,
      isLocked: x.is_locked,
      isActive: x.is_active,
      createdBy: x.created_by,
      createdDateTime: x.created_date_time,
      updatedBy: x.updated_by,
      updatedDateTime: x.updated_date_time,
      roleId: x.roles[0].uuid
    }

  },
}


