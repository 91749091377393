import i18n from '@/i18n'
import Swal from "sweetalert2";

/* Functions here is everything general */
import { PLATFORM } from '@/constants/enums'

export function removeCurrencyFormat(value) {
  return Number(value.replace(/,+/g, ''))
}

export function isNullOrEmpty(value) {
  return value == undefined || value == 'undefined' || value == null || value == ''
}

// eslint-disable-next-line no-unused-vars
export function apiPaginationAdapter({ current_page, last_page, prev_page_url, next_page_url, per_page, total, count }) {
  return {
    pageNumber: Number(current_page),
    pageSize: Number(per_page),
    lastPage: Number(last_page),
    tableRowsCounts: Number(count),
    total: Number(total)
  }
}

export function getClientSidePagination({ per_page, current_page, count }) {
  return {
    pageNumber: Number(current_page),
    pageSize: Number(per_page),
    tableRowsCounts: Number(count)
  }
}

export function getErrorMessage(error) {
  var errorMessage = i18n.t("message.unknownError");
  if(error.alertMessage) {
    errorMessage = error.alertMessage; 
  }
  else if(error.response.data.alertMessage)
  {
    errorMessage = error.response.data.alertMessage;
  }

  return errorMessage; 
}

export function getHostname() {
  return window.location.origin
}

export function getPlatform() {
  let type = PLATFORM.DESKTOP
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/is) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    type = PLATFORM.MOBILE
  } else {
    type = PLATFORM.DESKTOP
  }
  return type
}

export function alertSuccessMessage(message) {
  Swal.fire(i18n.t('message.success'), message, 'success')
}

export function alertSuccessMessageWithReload(message) {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-primary",
    },
    buttonsStyling: false
  });

  swalWithBootstrapButtons
    .fire({
      title: i18n.t('message.success'),
      text: message,
      icon: "success",
      confirmButtonText: "OK",
    })
    .then(result => {
      if (result.value) {
        location.reload();
      } 
    });
}

export function alertErrorMessage(message, code) {
  Swal.fire(i18n.t('message.error'), message + ' (' + code + ')'  , 'error');
}

