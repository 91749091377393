import axios from 'axios'
import { APIHELPER } from '@/constants'
import { sessionHelper } from '@/utils'
import store from '@/state/store'
import qs from 'qs'

export function apiHelper(baseURL, appendAuthHeader = true, checkTokenExpired = true) {
  let authHeader = {}
  let userToken = sessionHelper.getUserToken();
  if (userToken && appendAuthHeader) {
    authHeader.Authorization = `Bearer ${userToken}`
  }

  const apiInstance = axios.create({
    baseURL,
    paramsSerializer(params) {
      return qs.stringify(params, { arrayFormat: 'indices', encodeValuesOnly: true })
    }
  })

  async function checkExpired() {
    if(checkTokenExpired == true) {
      var tokenExpired = sessionHelper.isTokenExpired();
      if (tokenExpired == false) {
        var refreshTokenExpired = sessionHelper.isRefreshTokenExpired();
        if (refreshTokenExpired == false) {
         await store.dispatch('auth/logout');
        }
        else {
         await store.dispatch('auth/refreshToken');
        }
      }
    }
  }

  const responseHandler = (response) => {
    return response.data
  }

  const errorHandler = async (error) => {
   
    var errorMessage = "";
    var errorReturnMessage = "";
    var errorType = "";
    var errorKey = "";
    var errorResponse = error.response;
    var errorStatus = 0;
    var errorData = null;
    if (!errorResponse) {
      errorMessage = error.message;

      if (errorMessage === APIHELPER.NETWORK_ERROR) {
        errorReturnMessage = APIHELPER.NETWORK_ERROR;
      }
      else {
        errorReturnMessage = APIHELPER.UNKNOWN_ERROR;
      }
    }
    else {
      errorStatus = error.response.status;
      switch (errorStatus) {
        case 401:
          errorReturnMessage = APIHELPER.INVALID_USER;
          await checkExpired();
          break;
        case 400:
          errorType = typeof error.response.data.error;
          errorData = error.response.data.error;

          if (errorType === APIHELPER.OBJECT) {

            Object.keys(errorData).map(
              (key) => {
                if (errorKey === '') {
                  errorKey = key;
                } else {
                  errorKey += ',' + key;
                }
              }
            );
            errorReturnMessage = errorKey + APIHELPER.OBJECT_INVALID;
          }
          else {
            errorReturnMessage = errorData;
          }
          break;
        default:
          errorReturnMessage = error.response.data.error;
      }
      error.response.data.alertMessage = errorReturnMessage;
      throw error
    }
  }


  apiInstance.interceptors.request.use(async (config) => {
    if (userToken) {
      await checkExpired();
    }
    userToken = sessionHelper.getUserToken();

    if (userToken && appendAuthHeader) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${userToken}`
      }
    }

    return config
  })

  apiInstance.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
  )

  return apiInstance
}
