import { lookupService } from '../../helpers/services/lookup.service';
import i18n from '@/i18n'
import { sharedHelper } from '@/utils'

export const state = {
  roleDdl: [],
  permissionDdl: [],
  roomDdl: [],
};

export const LOOKUP_MODULE = "lookup";

// define action 
export const LOOKUP_ROLE = 'lookupRole';
export const LOOKUP_PERMISSION = 'lookupPermission';
export const LOOKUP_MEETING_ROOM = 'lookupMeetingRoom';

// define mutation
const ROLE_LOOKUP = "roleLookup";
const PERMISSION_LOOKUP = "permissionLookup";
const MEETING_ROOM_LOOKUP = "meetingRoomLookup";

export const mutations = {

    [ROLE_LOOKUP](state, { data }) {
      let p = []
      let response = data
  
      if (response.success) {
        response.data.forEach((x) => {
          p.push({
            value: x.value,
            text: x.name
          })
        })
  
        state.roleDdl = p
      } else {
        state.roleDdl = []
      }
    },

    [MEETING_ROOM_LOOKUP](state, { data }) {
      let p = []
      let response = data
  
      if (response.success) {
        response.data.forEach((x) => {
          p.push({
            value: x.value,
            text: x.name
          })
        })
  
        state.roomDdl = p
      } else {
        state.roomDdl = []
      }
    },

    // eslint-disable-next-line no-unused-vars
    [PERMISSION_LOOKUP](state, { data, hasOptionAll }) {
  
      function getCodeAndLabelFromPermission(permission) {
        return {
          code: permission.code,
          label: i18n.t(`permissions.${permission.code}`)
        }
      }
  
      state.permissionDdl = data.data.map((permissionModule) => {
        return {
          module: permissionModule.module,
          submodule: permissionModule.submodule,
          permissions: permissionModule.permissions.map(getCodeAndLabelFromPermission)
        }
      })
    },

};

export const actions = {
    // eslint-disable-next-line no-unused-vars
    async [LOOKUP_ROLE]({ commit }, { hasOptionAll }) {
        await lookupService.getLookupRoles().then(
          (data) => {
            commit(ROLE_LOOKUP, { data })
          },
          (error) => {
            let message = 'Role Lookup ' + sharedHelper.getErrorMessage(error);
            sharedHelper.alertErrorMessage(message, error.response.data.code)
            commit(ROLE_LOOKUP, { error, hasOptionAll })
          }
        )
      },

      async [LOOKUP_MEETING_ROOM]({ commit }, { hasOptionAll, id }) {
        await lookupService.getiHextarMeetingRoom(id).then(
          (data) => {
            commit(MEETING_ROOM_LOOKUP, { data })
          },
          (error) => {
            let message = 'Meeting Room Lookup ' + sharedHelper.getErrorMessage(error);
            sharedHelper.alertErrorMessage(message, error.response.data.code)
            commit(MEETING_ROOM_LOOKUP, { error, hasOptionAll })
          }
        )
      },

      [LOOKUP_PERMISSION]({ commit }, { hasOptionAll }) {
        lookupService.getAllPermissions().then(
          (data) => {
            commit(PERMISSION_LOOKUP, { data, hasOptionAll })
          },
          (error) => {
            let message = 'Permission Lookup ' + sharedHelper.getErrorMessage(error);
            sharedHelper.alertErrorMessage(message, error.response.data.code)
            commit(PERMISSION_LOOKUP, { error, hasOptionAll })
          }
        )
      },
};
