/* Functions here is to handle everything about
 * drop down list value that not getting from API call
 */
import { SHARED } from '@/constants'
import { ACTIVATION_STATUS } from '@/constants/enums'
import i18n from '@/i18n'

export function getOptionAll(isStringVal = false) {
  return {
    title: i18n.t('label.all'),
    text: i18n.t('label.all'),
    value: isStringVal ? SHARED.DEFAULT_ALL_VALUE_STR : SHARED.DEFAULT_ALL_VALUE
  }
}

export function getActivationStatus(hasOptionAll, isBoolValue = false) {
  let ddl = []

  if (hasOptionAll) {
    ddl.push(getOptionAll())
  }

  ddl.push({
    text: i18n.t(`enumActivationStatus[0].${ACTIVATION_STATUS.ACTIVE}`),
    value: isBoolValue ? Boolean(ACTIVATION_STATUS.ACTIVE) : ACTIVATION_STATUS.ACTIVE
  })
  ddl.push({
    text: i18n.t(`enumActivationStatus[0].${ACTIVATION_STATUS.INACTIVE}`),
    value: isBoolValue ? Boolean(ACTIVATION_STATUS.INACTIVE) : ACTIVATION_STATUS.INACTIVE
  })

  return ddl
}

export function getAccountLockStatus(hasOptionAll, isBoolValue = false) {
  let ddl = []

  if (hasOptionAll) {
    ddl.push(getOptionAll())
  }

  ddl.push({
    text: i18n.t(`enumAccountLockStatus[0].${ACTIVATION_STATUS.ACTIVE}`),
    value: isBoolValue ? Boolean(ACTIVATION_STATUS.ACTIVE) : ACTIVATION_STATUS.ACTIVE
  })
  ddl.push({
    text: i18n.t(`enumAccountLockStatus[0].${ACTIVATION_STATUS.INACTIVE}`),
    value: isBoolValue ? Boolean(ACTIVATION_STATUS.INACTIVE) : ACTIVATION_STATUS.INACTIVE
  })

  return ddl
}

export function getLocationsFromLookups(locationsLookups) {
  return [
    {
      text: i18n.t('label.state'),
      value: SHARED.DEFAULT_ALL_VALUE_STR
    },
    ...locationsLookups.map((l) => {
      return {
        text: l.location,
        value: l.location
      }
    })
  ]
}
