import { apiHelper } from '@/utils'
import { API } from '@/constants'

export const lookupService = {
    getLookupRoles, 
    getAllPermissions, 
    getLookupUsers,
    getLookupLocations,
    getLookupCustomers,
    getLookupStaffTypes,
    getLookupStaffs,
    getLookupWorkshops,
    getLookupWorkshopTypes, 
    getLookupLanguages,
    getiHextarMeetingRoom
};

const MODULE = {
    LOOKUP: 'lookup',
    ROLES: 'roles', 
    PERMISSIONS: 'permissions', 
    USERS: 'users',
    LOCATION: 'locations', 
    CUSTOMERS: 'customers', 
    STAFF_TYPES: 'staff-types',
    STAFFS: 'staffs', 
    WORKSHOPS: 'workshops', 
    WORKSHOP_TYPES: 'workshop-types',
    LANGUAGES: 'languages',
    IHEXTAR_USERS: 'ihextar-users',
    MEETING_ROOM: 'meeting-rooms',
}

// const API_URL = API.BASE_URL.replace('@serviceName', 'user') + '/v1'
const API_URL = API.BASE_URL + '/v1'

export async function getLookupRoles() {
    const requestOptions = {
      params: {
        is_active: 1
      }
    }
    return await apiHelper(API_URL).get(`/${MODULE.LOOKUP}/${MODULE.ROLES}`, requestOptions)
}

export function getAllPermissions() {
  return apiHelper(API_URL).get(`/${MODULE.LOOKUP}/${MODULE.PERMISSIONS}`)
}

export function getLookupUsers() {
  return apiHelper(API_URL).get(`/${MODULE.LOOKUP}/${MODULE.USERS}`)
}

export function getLookupLocations() {
return apiHelper(API_URL).get(`/${MODULE.LOOKUP}/${MODULE.LOCATION}`)
}

export function getLookupCustomers() {
  return apiHelper(API_URL).get(`/${MODULE.LOOKUP}/${MODULE.CUSTOMERS}`)
}

export function getLookupStaffTypes() {
return apiHelper(API_URL).get(`/${MODULE.LOOKUP}/${MODULE.STAFF_TYPES}`)
}

export function getLookupStaffs() {
  return apiHelper(API_URL).get(`/${MODULE.LOOKUP}/${MODULE.STAFFS}`)
}

export function getLookupWorkshops() {
return apiHelper(API_URL).get(`/${MODULE.LOOKUP}/${MODULE.WORKSHOPS}`)
}

export function getLookupWorkshopTypes() {
  return apiHelper(API_URL).get(`/${MODULE.LOOKUP}/${MODULE.WORKSHOP_TYPES}`)
}

export async function getLookupLanguages() {
  return await apiHelper(API_URL).get(`/${MODULE.LOOKUP}/${MODULE.LANGUAGES}`)
}

export async function getIHextarUserLanguages() {
  return await apiHelper(API_URL).get(`/${MODULE.LOOKUP}/${MODULE.IHEXTAR_USERS}`)
}

export async function getiHextarMeetingRoom(id) {
  const requestOptions = {
    params: {
      company_id: id,
    }
  }

  return apiHelper(API_URL).get(`/${MODULE.LOOKUP}/${MODULE.MEETING_ROOM}`, requestOptions)
}

